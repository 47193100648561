import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import styled from 'styled-components';
import numeral from 'numeral';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileContract } from '@fortawesome/free-solid-svg-icons';

import { useUser } from '../../../contexts/UserContext';
import { useForm } from '../../../hooks/useForm';
import {
  useFetchClaims,
  useUpdateClaim,
  useDeleteClaim,
} from '../../../services/claims';
import { useDeleteLead } from '../../../services/leads';
import Loading from '../../common/Loading';
import AssignToModal from '../../claim/AssignToModal';
import AssignNewVaRatingModal from '../../claim/AssignNewVaRatingModal';
import MeetingInviteModal from '../../calendar/components/MeetingInviteModal';

import ActivityBar from './components/ActivityBar';
import Toolbar from './components/Toolbar';
import SalesCard from './components/SalesCard';
import LeadCard from './components/LeadCard';
import LeadDetailsModal from './components/LeadDetailsModal';
import AssignLeadToModal from '../../common/AssignLeadToModal';
import SendInvoiceModal from '../../common/SendInvoiceModal';
import { availableTransitions } from '../../../lib/claimHelpers';

const StyledColumn = styled.div`
  width: 100%;
  background: rgba(255, 255, 255, 0.65);
  border: 1px solid #ddd;
  padding: 0.5rem;
  padding-bottom: 0;
  border-radius: 5px;

  & > div:first-child {
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #ccc;
  }

  & > div:first-child p {
    margin-bottom: 0;
  }

  & > div.dragging-over {
    background: #eee;
  }

  & .list {
    flex: 1;
  }
`;

const VIEW_KEY = 'view_v2';

/*
'intake',
'awaiting medical records',
'in process',
'awaiting internal review',
'internal review approved',
'submitted to va',
'invoiced',
'payment plan',
'paid',
'dead',
*/
const COLUMNS = [
  {
    id: 'leads',
    label: 'Leads',
  },
  {
    id: 'intake',
    label: 'Intake',
  },
  {
    id: 'awaiting medical records',
    label: 'Awaiting Med. Recs.',
  },
  {
    id: 'in process',
    label: 'In Process',
  },
  {
    id: 'awaiting internal review',
    label: 'Awaiting Review',
  },
  {
    id: 'internal review approved',
    label: 'Review Approved',
  },
  {
    id: 'submitted to va',
    label: 'Submitted',
  },
  {
    id: 'invoiced',
    label: 'Invoiced',
  },
  {
    id: 'payment plan',
    label: 'Payment Plan',
  },
  {
    id: 'paid',
    label: 'Paid',
  },
  {
    id: 'dead',
    label: 'Dead',
  },
];

const getDefaultView = () => {
  const view = window.localStorage.getItem(VIEW_KEY);

  if (view && /^\[/.test(view)) {
    return JSON.parse(view);
  }

  return COLUMNS.map((c) => c.id);
};

const SalesDashboard = () => {
  const { user } = useUser();
  const [cols] = useState([...COLUMNS]);
  const [availableStatuses, setAvailableStatuses] = useState([]);
  const [view, setView] = useState(getDefaultView());
  const [items, setItems] = useState([]);
  const [assignTo, setAssignTo] = useState(null);
  const [completeClaim, setCompleteClaim] = useState(null);
  const [meetingInvite, setMeetingInvite] = useState(null);
  const [showLead, setShowLead] = useState(null);
  const [assignLeadTo, setAssignLeadTo] = useState(null);
  const [sendInvoice, setSendInvoice] = useState(null);
  const { form, onChange } = useForm({
    search: '',
    status: 'active',
    user: null,
  });
  const [filters, setFilters] = useState({
    page: 1,
    limit: 1000,
    search: undefined,
    status: 'active',
    userId: undefined,
    leads: 1,
    statuses: view.join(','),
  });
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { isLoading, data } = useFetchClaims(filters);

  const deleteLead = useDeleteLead(() => {
    toast.success('The selected lead has been successfully deleted.');
    queryClient.invalidateQueries({ queryKey: 'claims' });
  });

  useEffect(() => {
    if (!data) {
      return;
    }

    setItems([...data.rows]);
  }, [data]);

  useEffect(() => {
    window.localStorage.setItem(VIEW_KEY, JSON.stringify(view));
    setFilters({ ...filters, statuses: view.join(',') });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view]);

  const updateClaim = useUpdateClaim(
    () => {
      toast.success('Claim status was successfully updated.');
      queryClient.invalidateQueries('claims');
    },
    { onError: (err) => toast.error(err.message) }
  );

  const deleteClaim = useDeleteClaim(
    () => {
      toast.success('The selected claim has been deleted.');
      queryClient.invalidateQueries('claims');
    },
    { onError: (err) => toast.error(err.message) }
  );

  const onSubmit = (e) => {
    e.preventDefault();
    setFilters({
      ...filters,
      search: form.search,
      status: form.status,
      claimAssignedToUserId: form.user ? form.user.value : undefined,
      page: 1,
    });
  };

  useEffect(() => {
    if (data) {
      onSubmit({ preventDefault: () => {} });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.user]);

  const onDragEnd = (result) => {
    const newItems = [...items];

    if (!result.destination || !result.destination.droppableId) {
      return;
    }

    // update the status of the record in question
    const item = newItems.find((i) => i.claimId === result.draggableId);
    if (!item) {
      return;
    }

    item.claimStatus = result.destination.droppableId;
    item.claimUpdatedOn = moment.utc().format();

    updateClaim.mutate({
      claimId: item.claimId,
      payload: { claimStatus: item.claimStatus },
    });

    setItems([...newItems]);
  };

  const onBeforeCaptureResponder = (before) => {
    const claim = data.rows.find((r) => r.claimId === before.draggableId);

    if (!claim) {
      toast.error(
        'It looks like we encountered an error with this object. Please refresh the page to try and resolve the problem.'
      );
      return;
    }

    const canMoveTo = availableTransitions(
      claim.claimStatus,
      user.userType,
      user.userEmail
    );

    setAvailableStatuses([...canMoveTo, claim.claimStatus]);
  };

  const getActions = () => {
    if (user.userType === 'provider') {
      return [
        {
          label: 'View Details',
          onClick: (row) => navigate(`/admin/claim/${row.claimId}`),
        },
      ];
    }

    const actions = [
      {
        label: 'View Details',
        onClick: (row) => navigate(`/admin/profile/${row.claimId}`),
      },
      {
        label: 'Assign To',
        onClick: (row) =>
          setAssignTo({
            claimId: row.claimId,
            userId: row.userId,
            adminId: row.assignedToUserId,
            adminFirstName: row.assignedToUserFirstName,
            adminLastName: row.assignedToUserLastName,
          }),
      },
      {
        label: 'Create Meeting Invite',
        onClick: (row) => {
          setMeetingInvite({
            userId: {
              label: `${row.userFirstName} ${row.userLastName}`,
              value: row.userId,
              record: { userEmail: row.userEmail || '' },
            },
          });
        },
      },
      {
        divider: true,
      },
      {
        label: 'Assign New VA Rating',
        onClick: (row) =>
          setCompleteClaim({
            claimId: row.claimId,
            claimNewVaRating: row.claimNewVaRating,
            claimCurrentRating: row.claimCurrentRating,
          }),
      },
      {
        divider: true,
      },
      {
        label: 'Delete',
        onClick: (row) => {
          if (window.confirm('Are you sure you want to delete this claim?')) {
            deleteClaim.mutate(row.claimId);
          }
        },
      },
    ];

    if (user._admin && user._admin.adminRole === 'admin') {
      actions.splice(actions.length - 2, 0, {
        label: 'Create Invoice',
        onClick: (row) => {
          if (!row.claimNewVaRating) {
            alert('Please add a new VA Rating before sending invoices.');
          } else {
            setSendInvoice(row);
          }
        },
      });
    }

    return actions;
  };

  const renderLeadCards = () => {
    if (!data || !data.leads || data.leads.length === 0) {
      return [];
    }

    return data.leads.map((lead, lIdx) => {
      return (
        <LeadCard
          key={lIdx}
          lead={lead}
          onSelect={() => setShowLead(lead)}
          onAssignTo={() => setAssignLeadTo(lead)}
          onDelete={() => deleteLead.mutate(lead.leadId)}
        />
      );
    });
  };

  return (
    <div>
      <ActivityBar />
      <Toolbar
        data={items}
        total={data ? data.total : 0}
        form={form}
        onChange={onChange}
        onSubmit={onSubmit}
        columns={COLUMNS}
        view={view}
        onViewChange={(newView) => setView(newView)}
      />
      <DragDropContext
        onDragEnd={onDragEnd}
        onBeforeCapture={onBeforeCaptureResponder}
      >
        <div className="d-flex justify-content-between align-items-start gap-1">
          {cols
            .filter((c) => view.includes(c.id))
            .map((c, cIdx) => {
              let min = 0;
              let max = 0;
              let total =
                c.id === 'leads' && data && data.leads ? data.leads.length : 0;

              let cards =
                c.id === 'leads'
                  ? renderLeadCards()
                  : items
                      .filter((i) => i.claimStatus === c.id)
                      .map((item, itemIdx) => {
                        min += item._minValue;
                        max += item._maxValue;
                        total++;

                        return (
                          <Draggable
                            key={item.claimId}
                            draggableId={item.claimId}
                            index={itemIdx}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <SalesCard
                                  card={item}
                                  handleProps={provided.dragHandleProps}
                                  className={
                                    snapshot.isDragging ? 'dragging' : ''
                                  }
                                  actions={getActions()}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      });

              const isAvailToMOveTo = availableStatuses.includes(c.id);

              return (
                <div key={cIdx} style={{ flex: 1 }}>
                  <StyledColumn>
                    <div>
                      <h5>{c.label}</h5>
                      <div className="d-flex justify-content-between align-items-center">
                        <p>
                          <FontAwesomeIcon icon={faFileContract} fixedWidth />{' '}
                          {total === 0
                            ? `$0`
                            : `$${numeral(min).format('0,0')} - $${numeral(
                                max
                              ).format('0,0')}`}
                        </p>
                        <p>({total})</p>
                      </div>
                    </div>
                    <Droppable
                      droppableId={c.id}
                      isDropDisabled={!isAvailToMOveTo}
                    >
                      {(provided, snapshot) => {
                        return (
                          <div
                            className={
                              snapshot.isDraggingOver ? 'dragging-over' : ''
                            }
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {cards}
                            {provided.placeholder}
                          </div>
                        );
                      }}
                    </Droppable>
                  </StyledColumn>
                </div>
              );
            })}
        </div>
      </DragDropContext>
      {isLoading ? <Loading /> : null}
      <AssignToModal
        show={assignTo !== null}
        toggle={() => setAssignTo(null)}
        claim={assignTo}
        onSuccess={() => queryClient.invalidateQueries('claims')}
      />
      <AssignNewVaRatingModal
        show={completeClaim !== null}
        toggle={() => setCompleteClaim(null)}
        claim={completeClaim}
        onSuccess={() => queryClient.invalidateQueries('claims')}
      />
      <MeetingInviteModal
        show={meetingInvite !== null}
        toggle={() => setMeetingInvite(null)}
        onSuccess={() => {
          queryClient.invalidateQueries('calendar');
        }}
        meetingInvite={meetingInvite}
      />
      <LeadDetailsModal
        show={showLead !== null}
        toggle={() => setShowLead(null)}
        lead={showLead}
      />
      <AssignLeadToModal
        show={assignLeadTo !== null}
        toggle={() => setAssignLeadTo(null)}
        lead={assignLeadTo}
        onSuccess={() => {
          queryClient.invalidateQueries('claims');
        }}
      />
      <SendInvoiceModal
        show={sendInvoice !== null}
        toggle={() => setSendInvoice(null)}
        claim={sendInvoice || {}}
      />
    </div>
  );
};

export default SalesDashboard;
