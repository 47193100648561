import React, { useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { useForm } from '../../hooks/useForm';
import { useUpdateClaim } from '../../services/claims';

const AssignNewVaRatingModal = ({ show, toggle, claim, onSuccess }) => {
  const { form, onChange, setValues } = useForm({ claimNewVaRating: null });

  const updateClaim = useUpdateClaim(
    () => {
      toast.success('The new VA rating has been successfully assigned.');
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
      toggle();
    },
    { onError: (err) => toast.error(err.message) }
  );

  useEffect(() => {
    if (claim && claim.claimNewVaRating) {
      setValues(
        {
          claimNewVaRating: claim.claimNewVaRating,
        },
        true
      );
    } else {
      setValues({ claimNewVaRating: null }, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claim]);

  const onSubmit = (e) => {
    e.preventDefault();

    const val = Number(form.claimNewVaRating);
    if (isNaN(val) || val < 1 || val > 100) {
      toast.error(
        'Invalid rating provided. Please provide a number between 1 and 100'
      );
      return;
    }

    updateClaim.mutate({
      claimId: claim.claimId,
      payload: {
        claimNewVaRating: form.claimNewVaRating,
        claimStatus: 'submitted to va',
      },
    });
  };

  return (
    <Modal show={show} onHide={() => toggle()}>
      <Modal.Body>
        <form onSubmit={onSubmit}>
          <Form.Group>
            <Form.Label>New VA Rating</Form.Label>
            <Form.Control
              name="claimNewVaRating"
              type="number"
              step="10"
              value={form.claimNewVaRating || 0}
              min={
                claim && claim.claimCurrentRating ? claim.claimCurrentRating : 0
              }
              max="100"
              placeholder="New VA Rating"
              onChange={onChange}
            />
          </Form.Group>
          <hr />
          <Button
            variant="success"
            type="submit"
            disabled={updateClaim.isLoading}
          >
            <FontAwesomeIcon
              icon={updateClaim.isLoading ? faSpinner : faCheck}
              spin={updateClaim.isLoading}
            />{' '}
            Assign
          </Button>{' '}
          <Button variant="link" onClick={() => toggle()}>
            Cancel
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AssignNewVaRatingModal;
