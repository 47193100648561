import React, { useEffect } from 'react';
import { Modal, Form, Button, Row, Col, Alert } from 'react-bootstrap';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { useForm } from '../../hooks/useForm';
import numeral from 'numeral';

const DEFAULT_VALS = {
  numInvoices: 1,
  timeBetween: 1,
  dueDate: '',
};

const SendInvoiceModal = ({ show, toggle, claim }) => {
  const { form, onChange, setValues } = useForm({ ...DEFAULT_VALS });

  useEffect(() => {
    let newVals = {
      claimId: claim.claimId || null,
      ...DEFAULT_VALS,
      dueDate: moment().add(1, 'month').startOf('month').format('YYYY-MM-DD'),
    };

    setValues(newVals);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claim]);

  const onSubmit = (e) => {
    e.preventDefault();
  };

  const renderDetails = () => {
    if (!claim || !claim._fees) {
      return null;
    }

    // convert the new rate to the index
    const newRateIndex = Math.floor(claim.claimNewVaRating * 0.1) - 1; // 0 based index for the fees
    const amount = Math.floor(claim._fees[newRateIndex] * 100) * 0.01;
    const amountPerInvoice = form.numInvoices
      ? Math.floor((amount / form.numInvoices) * 100) * 0.01
      : 0;

    return (
      <Row>
        <Col>
          <Form.Label>Rating Change</Form.Label>
          <p>
            {claim.claimCurrentRating}%{' '}
            <span className="ms-2 me-2" style={{ fontSize: '0.75rem' }}>
              <FontAwesomeIcon icon={faArrowRight} />
            </span>{' '}
            {claim.claimNewVaRating}%
          </p>
        </Col>
        <Col>
          <Form.Label>Total Due</Form.Label>
          <p>{numeral(amount).format('$0,0.00')}</p>
        </Col>
        {form.numInvoices > 1 ? (
          <Col>
            <Form.Label>Per Invoice</Form.Label>
            <p>{numeral(amountPerInvoice).format('$0,0.00')}</p>
          </Col>
        ) : null}
      </Row>
    );
  };

  return (
    <Modal show={show} onHide={() => toggle()}>
      <Modal.Body>
        <h3>Create Invoice</h3>
        <p>
          Use the form below to generate a new invoice or set of invoices for a
          completed/updated claim.
        </p>
        <hr />
        {renderDetails()}
        <form onSubmit={onSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Number of Invoices</Form.Label>
            <Form.Control
              type="number"
              name="numInvoices"
              value={form.numInvoices}
              onChange={onChange}
              required
              min={1}
              step={1}
            />
            <small>
              This number determines how many payments the total can be broken
              into.
            </small>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              {form.numInvoices > 1 ? 'First ' : ''}Due Date
            </Form.Label>
            <Form.Control
              type="date"
              name="dueDate"
              value={form.dueDate}
              onChange={onChange}
              required
              min={moment().format('YYYY-MM-DD')}
            />
          </Form.Group>
          <Form.Group className={form.numInvoices > 1 ? 'mb-3' : 'mb-3 d-none'}>
            <Form.Label>Months Between Invoices</Form.Label>
            <Form.Control
              type="number"
              name="timeBetween"
              value={form.timeBetween}
              onChange={onChange}
              required
              min={1}
            />
          </Form.Group>

          <hr />
          <Alert bg="warning">
            <p className="mb-0">
              Creating invoices will automatically start the billing process by
              email the client once the invoices are created in Stripe.
            </p>
          </Alert>

          <div>
            <Button type="submit" variant="primary">
              Save
            </Button>
            <Button variant="link" onClick={() => toggle()}>
              Cancel
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default SendInvoiceModal;
