import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import moment from 'moment-timezone';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import Markdown from 'react-markdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faEnvelope,
  faPhone,
  faPen,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';

import { RoundButton, Label, Value } from './Profile.elements';
import EditClaimModal from '../../claim/EditClaimModal';
import UserPickerModal from './UserPickerModal';
import {
  useToggleClaimTask,
  useUpdateClaim,
  useSetClaimAssignment,
} from '../../../services/claims';
import { ucwords } from '../../../lib/strings';
import day from '../../../lib/day';
import { availableTransitions } from '../../../lib/claimHelpers';

const SideBar = ({ data, user, onCreateActivityClicked }) => {
  const [showFullTaskList, setShowFullTaskList] = useState(false);
  const [showUserPicker, setShowUserPicker] = useState(null);
  const [edit, setEdit] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const toggleClaimTask = useToggleClaimTask(
    () => {
      queryClient.invalidateQueries('claim-profile');
      toast.success('The task has been successfully toggled.');
    },
    { onError: (err) => toast.error(err.message) }
  );

  const setClaimAssignment = useSetClaimAssignment(
    () => {
      queryClient.invalidateQueries('claim-profile');
      setShowUserPicker(null);
      toast.success('You changes have been successfully saved.');
    },
    { onError: (err) => toast.error(err.message) }
  );

  const updateClaim = useUpdateClaim(
    () => {
      toast.success('Claim status was successfully updated.');
      queryClient.invalidateQueries('claim-profile');
    },
    { onError: (err) => toast.error(err.message) }
  );

  const onValueUpdate = (field, value) => {
    updateClaim.mutate({
      claimId: data.claim.claimId,
      payload: { [field]: value },
    });
  };

  const [tasks, completedTasks, totalRemainingTasks] = useMemo(() => {
    const list = data.tasks.filter((t) => t.claimTaskCompletedOn === null);
    const completed = data.tasks.filter((t) => t.claimTaskCompletedOn !== null);
    if (!showFullTaskList) {
      return [list.slice(0, 3), completed, list.length];
    }
    return [list, completed, list.length];
  }, [data.tasks, showFullTaskList]);

  const trueRating = useMemo(() => {
    if (
      !data.claim.claimRatingsBreakdown ||
      data.claim.claimRatingsBreakdown.length === 0
    ) {
      return null;
    }

    let total = 0;
    data.claim.claimRatingsBreakdown.forEach((row) => {
      total += Number(row.rating);
    });

    return total;
  }, [data]);

  const availStatuses = useMemo(() => {
    if (!data || !data.claim) {
      return [];
    }

    const newStatuses = availableTransitions(
      data.claim.claimStatus,
      user.userType === 'admin' ? user._admin.adminRole : 'user',
      user.userEmail
    );

    return [...newStatuses, data.claim.claimStatus];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <Card className="mb-3">
        <Card.Body>
          <h2 className="text-center mb-3" style={{ color: '#445768' }}>
            {data.user.userFirstName} {data.user.userLastName}
          </h2>
          <div
            className="d-flex justify-content-center"
            style={{ gap: '2rem' }}
          >
            <RoundButton
              variant="link"
              size="sm"
              className="btn-round"
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </RoundButton>
            <RoundButton
              variant="link"
              size="sm"
              className="btn-round"
              onClick={() => window.open(`mailto:${data.user.userEmail}`)}
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </RoundButton>
            <RoundButton
              variant="link"
              size="sm"
              className="btn-round"
              onClick={() =>
                window.open(
                  `tel:+1${data.user.userPhone.replace(/[^\d]/g, '')}`
                )
              }
              disabled={data.user.userPhone === null}
            >
              <FontAwesomeIcon icon={faPhone} />
            </RoundButton>
            <RoundButton
              variant="link"
              size="sm"
              className="btn-round"
              onClick={() => setEdit(true)}
            >
              <FontAwesomeIcon icon={faPen} fixedWidth />
            </RoundButton>
            <RoundButton
              variant="link"
              size="sm"
              className="btn-round"
              onClick={() => onCreateActivityClicked()}
            >
              <FontAwesomeIcon icon={faPlus} fixedWidth />
            </RoundButton>
          </div>
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Body>
          <Row>
            <Col xs={6}>
              <Label>Status</Label>
              <Form.Select
                name="claimStatus"
                value={data.claim.claimStatus}
                size="sm"
                className="mb-3"
                onChange={(e) => onValueUpdate(e.target.name, e.target.value)}
              >
                {[
                  'intake',
                  'awaiting medical records',
                  'in process',
                  'awaiting internal review',
                  'internal review approved',
                  'submitted to va',
                  'invoiced',
                  'payment plan',
                  'paid',
                  'dead',
                ].map((opt, optIdx) => {
                  return (
                    <option
                      value={opt}
                      key={optIdx}
                      disabled={!availStatuses.includes(opt)}
                    >
                      {ucwords(opt)}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>
            <Col xs={6}>
              <Label>VA Claim Type</Label>
              <Form.Select
                name="claimVaType"
                value={data.claim.claimVaType}
                size="sm"
                className="mb-3"
                onChange={(e) => onValueUpdate(e.target.name, e.target.value)}
              >
                {[
                  'initial',
                  'supplemental appeal',
                  'higher rate review',
                  'VA court appeal',
                ].map((opt, optIdx) => {
                  return (
                    <option value={opt} key={optIdx}>
                      {ucwords(opt)}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>
          </Row>

          <Row>
            <Col xs={6}>
              <Label>Referred By</Label>
              <Value>
                <Button
                  variant="link"
                  className="p-0"
                  onClick={() =>
                    setShowUserPicker({
                      label: 'Set Referred By',
                      userType: 'admin',
                      value: data.claim.refUser
                        ? {
                            value: data.claim.refUser.userId,
                            label: `${data.claim.refUser.userFirstName} ${data.claim.refUser.userLastName}`,
                          }
                        : null,
                      onSelect: (val) =>
                        setClaimAssignment.mutate({
                          claimId: data.claim.claimId,
                          body: { userReferredBy: val ? val.value : null },
                        }),
                    })
                  }
                >
                  {data.claim.refUser
                    ? `${data.claim.refUser.userFirstName} ${data.claim.refUser.userLastName}`
                    : 'Unassigned'}
                </Button>
              </Value>
            </Col>
            <Col xs={6}>
              <Label>Assigned To</Label>
              <Value>
                <Button
                  variant="link"
                  className="p-0"
                  onClick={() =>
                    setShowUserPicker({
                      label: 'Set Assigned To',
                      userType: 'admin',
                      value: data.claim.assignedUser
                        ? {
                            value: data.claim.assignedUser.userId,
                            label: `${data.claim.assignedUser.userFirstName} ${data.claim.assignedUser.userLastName}`,
                          }
                        : null,
                      onSelect: (val) =>
                        setClaimAssignment.mutate({
                          claimId: data.claim.claimId,
                          body: {
                            claimAssignedToUserId: val ? val.value : null,
                          },
                        }),
                    })
                  }
                >
                  {data.claim.assignedUser
                    ? `${data.claim.assignedUser.userFirstName} ${data.claim.assignedUser.userLastName}`
                    : 'Unassigned'}
                </Button>
              </Value>
            </Col>
          </Row>

          <Row>
            <Col xs={6}>
              <Label>Assigned Providers</Label>
              <Value>
                <Button
                  variant="link"
                  className="p-0"
                  onClick={() =>
                    setShowUserPicker({
                      label: 'Set Provider To',
                      userType: 'provider',
                      isMulti: true,
                      value: data.providers
                        ? data.providers.map((p) => ({
                            value: p.userId,
                            label: `${p.userFirstName} ${p.userLastName}`,
                          }))
                        : null,
                      onSelect: (val) =>
                        setClaimAssignment.mutate({
                          claimId: data.claim.claimId,
                          body: {
                            providers:
                              val && val.length > 0
                                ? val.map((p) => p.value)
                                : null,
                          },
                        }),
                    })
                  }
                >
                  {data.providers && data.providers.length > 0
                    ? data.providers
                        .map(
                          (provider) =>
                            `${provider.userFirstName} ${provider.userLastName}`
                        )
                        .join(', ')
                    : 'Unassigned'}
                </Button>
              </Value>
            </Col>
            {data.claim.partnerId ? (
              <Col xs={6}>
                <Label>Partner Referral</Label>
                <Value>{data.claim.partnerName}</Value>
              </Col>
            ) : null}
          </Row>
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Body>
          <Label>To Do/Tasks</Label>
          {tasks.length === 0 ? <p>All tasks have been completed.</p> : null}
          {tasks
            .filter((t) => t.claimTaskCompletedOn === null)
            .map((task, idx) => {
              return (
                <Form.Group key={idx}>
                  <Form.Check
                    id={task.claimTaskId}
                    label={task.claimTaskLabel}
                    checked={false}
                    disabled={toggleClaimTask.isLoading}
                    onChange={() =>
                      toggleClaimTask.mutate({
                        claimId: data.claim.claimId,
                        taskId: task.claimTaskId,
                      })
                    }
                  />
                </Form.Group>
              );
            })}
          {tasks.length < totalRemainingTasks ? (
            <Button
              size="sm"
              variant="link"
              className="w-100"
              onClick={() => setShowFullTaskList(true)}
            >
              Show All {totalRemainingTasks}
            </Button>
          ) : (
            <>
              {completedTasks.map((task, idx) => {
                return (
                  <Form.Group key={idx}>
                    <Form.Check
                      id={task.claimTaskId}
                      label={`${task.claimTaskLabel} completed on ${day(
                        task.claimTaskCompletedOn
                      ).format('MM/DD/YYYY')} by ${task.userFirstName} ${
                        task.userLastName
                      }`}
                      checked={true}
                      disabled={toggleClaimTask.isLoading}
                      onChange={() =>
                        toggleClaimTask.mutate({
                          claimId: data.claim.claimId,
                          taskId: task.claimTaskId,
                        })
                      }
                    />
                  </Form.Group>
                );
              })}
            </>
          )}
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Body>
          <Label>Notes</Label>
          {data.claim.claimNotes ? (
            <Markdown>{data.claim.claimNotes}</Markdown>
          ) : (
            <p>--</p>
          )}
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Body>
          <Row>
            <Col xs={6}>
              <Label>Phone</Label>
              <Value>{data.user.userPhone}</Value>
            </Col>
            <Col xs={6}>
              <Label>Phone Type</Label>
              <Value>{data.user.userPhoneType}</Value>
            </Col>
          </Row>
          <Label>Email</Label>
          <Value>{data.user.userEmail}</Value>
          <Row>
            <Col xs={6}>
              <Label>Date Of Birth</Label>
              <Value>
                {moment.utc(data.user.userDateOfBirth).format('MM/DD/YYYY')}
              </Value>
            </Col>
            <Col xs={6}>
              <Label>SSN</Label>
              <Value>{data.user.userSsn || '-'}</Value>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Label>Married</Label>
              <Value>{data.user.userMarried ? 'Yes' : 'No'}</Value>
            </Col>
            <Col xs={6}>
              <Label># Deps. Under 18</Label>
              <Value>{data.user.userDependentsUnder18}</Value>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Label>Military Status</Label>
              <Value>{data.user.userMilitaryStatus}</Value>
            </Col>
            <Col xs={6}>
              <Label>Combat Veteran</Label>
              <Value>{data.user.userCombatVeteran ? 'Yes' : 'No'}</Value>
            </Col>
          </Row>
          <Label>Vietnam Veteran</Label>
          <Value>{data.user.userIsVietnamVeteran ? 'Yes' : 'No'}</Value>
          <Row>
            <Col xs={6}>
              <Label>Current Rating</Label>
              <Value>{data.user.userCurrentVaRating}</Value>
            </Col>
            <Col xs={6}>
              <Label>True Rating</Label>
              <Value>{trueRating ? trueRating : 'N/A'}</Value>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Label>State</Label>
              <Value>{data.user.userState || '--'}</Value>
            </Col>
            <Col xs={6}>
              <Label>Timezone</Label>
              <Value>{data.user.userTimezone}</Value>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <Row>
            <Col xs={6}>
              <Label>Created On</Label>
              <Value>
                {moment
                  .utc(data.claim.claimCreatedOn)
                  .format('MM/DD/YYYY h:mm A')}
              </Value>
            </Col>
            <Col xs={6}>
              <Label>Updated On</Label>
              <Value>
                {moment
                  .utc(data.claim.claimUpdatedOn)
                  .format('MM/DD/YYYY h:mm A')}
              </Value>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <EditClaimModal
        show={edit}
        toggle={() => setEdit(false)}
        claim={{ ...data.claim, user: data.user }}
        onSuccess={() => queryClient.invalidateQueries('claim-profile')}
        includeNotes={true}
      />

      <UserPickerModal
        show={showUserPicker !== null}
        toggle={() => setShowUserPicker(null)}
        settings={showUserPicker || {}}
        busy={setClaimAssignment.isLoading}
      />
    </>
  );
};

export default SideBar;
